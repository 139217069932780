<template>
  <base-material-card
    icon="far fa-medal"
    :title="`Tabelle ${e.parent.name}`"
    color="primary"
  >
    <p>Die Endtabelle der {{ e.parent.name }} zum Ausdrucken.<br><b>Wichtig: der Wettkampf muss abgeschlossen sein!</b></p>
    <v-btn block @click="tabellen(e._parent)">Download</v-btn>
  </base-material-card>
</template>

<script>
import { usePdf } from '@/views/components/stb_w_2023/plugins/pdf'

export default {
  name: 'tabellen',

  setup (props, context) {
    return {
      ...usePdf(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object
    }
  }
}
</script>

<style scoped>

</style>
